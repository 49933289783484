<template>
  <div id="campaignGeneralInfoPage">
    <div class="loadingDiv" v-if="fetchingData" v-loading="true" style="min-height: 300px"></div>

    <template v-else>
      <!-- Campaign Steps -->
      <CampaignSteps v-if="campaign" :step="step" :campaign="campaign"></CampaignSteps>

      <div class="campaignInfo">
        <!-- Validation Error List -->
        <div class="rounded-md bg-red-600 p-4 mb-3" v-if="campaignErrors.length > 0">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/x-circle -->
              <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-2">
              <h3 class="text-sm font-medium text-white">There were 2 errors with your submission</h3>
              <div class="mt-2 text-sm text-white">
                <ul role="list" class="list-disc pl-3 space-y-1">
                  <li :key="index" v-for="(error, index) in campaignErrors">{{ error }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Basic Campaign Details -->
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>Basic Campaign Details</span>
          </div>

          <el-form class="campaignName" :model="campaign" ref="campaignInfoForm" label-position="top">
            <!-- Campaign Name -->
            <el-form-item label="Campaign Name" prop="name" :rules="[{ required: true, message: 'Campaign name is required' }]">
              <el-input class="w-72" type="text" v-model="campaign.name" placeholder="Enter campaign name" autocomplete="off"> </el-input>
            </el-form-item>

            <el-divider></el-divider>

            <el-form-item label="When you want to start campaign?">
              <el-radio-group v-model="campaign.start_type">
                <el-radio label="now">Start Immediately</el-radio>
                <el-radio label="later">Start Later</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- If Start type is later, provide later date -->
            <el-form-item label="Campaign Start Time" v-if="campaign.start_type == 'later'" class="-mt-5">
              <el-date-picker v-model="campaign.start_time" type="datetime" placeholder="Pick a date and time"> </el-date-picker>
            </el-form-item>

            <el-divider></el-divider>

            <div class="relative">
              <a href="https://support.growlytics.in/account-settings/campaign-settings/setting-up-frequency-capping" target="_blank" class="absolute right-0">
                <button class="text-secondary-800 background-transparent uppercase font-bold py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Learn More About Frequency Capping <i class="el-icon-top-right"></i></button>
              </a>
              <el-checkbox v-model="campaign.follow_capping" class="font-medium text-gray-700"> Follow Frequency Capping </el-checkbox>
              <div class="text-gray-400 text-xs pl-4 -mt-1">
                If set, message will not be sent if frequency capping limit reached for that customer.
                <a target="_blank" href="/settings/campaign-settings" class="text-primary-600">Click here</a>
                to check your frequency capping limits.
              </div>
            </div>

            <el-divider></el-divider>

            <UtmTrackingInput :channelType="campaign.channel" ref="utmInput"></UtmTrackingInput>
          </el-form>
        </el-card>

        <!-- In App Trigger Type -->
        <el-card class="triggerCriteria mt-4" shadow="never" v-if="campaign.channel == 'inApp'">
          <div slot="header" class="clearfix">
            <span>Trigger Criteria</span>
          </div>

          <div class="w-full">
            <el-radio-group v-model="triggerCriteria.type">
              <el-radio label="app-open" border>On App Open</el-radio>
              <el-radio label="screen-open" border>On specific screen</el-radio>
              <el-radio label="event-trigger" border>On custom event</el-radio>
            </el-radio-group>

            <!-- Hint -->
            <div class="text-gray-400 text-xs">
              <template v-if="triggerCriteria.type === 'app-open'"> Show on any screen as soon as the app is opened. </template>
              <template v-else-if="triggerCriteria.type === 'screen-open'"> Show only when a specific screen or app context is launched. </template>
              <template v-else-if="triggerCriteria.type === 'event-trigger'"> Show when the user performs any custom event. </template>
            </div>
          </div>

          <!-- Trigger Criteria Event -->
          <div class="mt-3 border rounded p-3" v-if="triggerCriteria.type === 'event-trigger'">
            <div class="text-sm font-semibold">Trigger Event</div>
            <div class="bg-red px-0 pt-2">
              <EventPicker ref="inAppTriggerEventPicker" :readOnly="readOnly" v-bind:selectedOnActionEvent="triggerCriteria.event" v-bind:channelType="'inApp'"></EventPicker>
            </div>
          </div>

          <!-- Display Settings -->
          <div class="mt-4 border rounded p-3" v-if="triggerCriteria.type !== 'app-open'">
            <div class="text-sm font-semibold">Display Settings (Optional)</div>

            <!-- Android - Display Settings -->
            <div class="flex w-full mt-3 relative border pt-4 pb-3 px-3 rounded">
              <div class="absolute bg-gray-800 text-white px-2 rounded-full text-xs" style="top: -8px"><i class="fa fa-android"> </i></div>
              <!-- Show only on screen -->
              <div class="flex-1 pr-3">
                <div class="text-gray-500 mb-1 text-xs">Show only on specific screens</div>

                <el-tag class="mr-1" :key="nameIndex" v-for="(name, nameIndex) in triggerCriteria.screen.android.name" closable :disable-transitions="false" @close="onRemoveScreenItem(triggerCriteria.screen.android, 'name')"> {{ name }} </el-tag>

                <el-input placeholder="com.package.MainActivity" v-model="triggerCriteria.screen.android.tempNameValue" class="input-with-select" style="width: 300px" size="small" @keyup.enter.native="onAddScreenItem(triggerCriteria.screen.android, 'name')">
                  <el-button class="addScreenButton" slot="append" type="primary" size="small" icon="el-icon-plus" @click="onAddScreenItem(triggerCriteria.screen.android, 'name')">Add</el-button>
                </el-input>
              </div>

              <!-- Show only on app context -->
              <div class="flex-1 pl-3">
                <div class="text-gray-500 text-xs mb-1">Show only on app context</div>

                <el-tag class="mr-1" :key="nameIndex" v-for="(appContext, nameIndex) in triggerCriteria.screen.android.appContext" closable :disable-transitions="false" @close="onRemoveScreenItem(triggerCriteria.screen.android, 'appContext')"> {{ appContext }} </el-tag>

                <el-input placeholder="com.package.MainActivity" v-model="triggerCriteria.screen.android.tempContextValue" class="input-with-select" style="width: 300px" size="small" @keyup.enter.native="onAddScreenItem(triggerCriteria.screen.android, 'appContext')">
                  <el-button class="addScreenButton" slot="append" type="primary" size="small" icon="el-icon-plus" @click="onAddScreenItem(triggerCriteria.screen.android, 'appContext')">Add</el-button>
                </el-input>
              </div>
            </div>

            <!-- iOS - Display Settings -->
            <div class="flex w-full mt-3 relative border pt-4 pb-3 px-3 rounded">
              <div class="absolute bg-gray-800 text-white px-2 rounded-full text-xs" style="top: -8px"><i class="fa-brands fa-apple"> </i></div>
              <!-- Show only on screen -->
              <div class="flex-1 pr-3">
                <div class="text-gray-500 mb-1 text-xs">Show only on specific screens</div>

                <el-tag class="mr-1" :key="nameIndex" v-for="(name, nameIndex) in triggerCriteria.screen.ios.name" closable :disable-transitions="false" @close="onRemoveScreenItem(triggerCriteria.screen.ios, 'name')"> {{ name }} </el-tag>

                <el-input placeholder="com.package.MainActivity" v-model="triggerCriteria.screen.ios.tempNameValue" class="input-with-select" style="width: 300px" size="small" @keyup.enter.native="onAddScreenItem(triggerCriteria.screen.ios, 'name')">
                  <el-button class="addScreenButton" slot="append" type="primary" size="small" icon="el-icon-plus" @click="onAddScreenItem(triggerCriteria.screen.ios, 'name')">Add</el-button>
                </el-input>
              </div>

              <!-- Show only on app context -->
              <div class="flex-1 pl-3">
                <div class="text-gray-500 text-xs mb-1">Show only on app context</div>

                <el-tag class="mr-1" :key="nameIndex" v-for="(appContext, nameIndex) in triggerCriteria.screen.ios.appContext" closable :disable-transitions="false" @close="onRemoveScreenItem(triggerCriteria.screen.ios, 'appContext')"> {{ appContext }} </el-tag>

                <el-input placeholder="com.package.MainActivity" v-model="triggerCriteria.screen.ios.tempContextValue" class="input-with-select" style="width: 300px" size="small" @keyup.enter.native="onAddScreenItem(triggerCriteria.screen.ios, 'appContext')">
                  <el-button class="addScreenButton" slot="append" type="primary" size="small" icon="el-icon-plus" @click="onAddScreenItem(triggerCriteria.screen.ios, 'appContext')">Add</el-button>
                </el-input>
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <!-- Step Footer -->
      <CampaignFooter :step="step" v-if="campaign" :campaign="campaign" @onNext="onGoNext" @onSaveDraft="onSaveDraft"></CampaignFooter>
    </template>
  </div>
</template>
<style lang="scss" src="./campaignGeneralInfoUpdate.scss"></style>

<script>
import campaignGeneralInfoPageComponent from './campaignGeneralInfoUpdateComponent';
export default campaignGeneralInfoPageComponent;
</script>
